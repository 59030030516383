<script setup lang="ts">
import { getFuzzySearch } from '@/apis/page'
import { useToast } from '@/components/ui/toast'
import { Input } from '~~/components/ui/input'

const { toast } = useToast()
const route = useRoute()
const input = ref('')
const inputEl = ref()
const inputDebounced = refDebounced(input, 1000)
// const { refetch, result } = getFuzzySearch(route.params.slug as string)

// watch(inputDebounced, (keyword: string) => refetch({ keyword }))
// watch(result, ({ search }) => {
//   const title = (search.pages.data as IPage[])
//     .map(({ attributes }) => attributes.Title)
//     .join(', ')

//   toast({
//     class: 'p-4 pr-10 w-max ml-auto',
//     title,
//     variant: 'default',
//   })
// })

const isMacOs = computed(
  () => false,
  // /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
)

// Using magickey to combine keys
const showSearch = ref(false)
const { Ctrl_K, MetaLeft_K } = useMagicKeys()

// Prevent default shortcut of browser [(Cmd | Ctrl) + K ]
useEventListener('keydown', (e) => {
  if ((e.ctrlKey || e.metaKey) && e.key === 'k') e.preventDefault()
})

watch([Ctrl_K, MetaLeft_K], (v) => {
  // Check combine keys
  // v[0]: Ctrl + K
  // v[1]: ⌘ + K
  if (v[0] || v[1]) {
    // console.log('Đã nhấn mở box')
    showSearch.value = true
  }
})

// Handle focus input
const { focused } = useFocus(inputEl, { initialValue: false })

whenever(focused, () => {
  showSearch.value = true
})

const searchAble = computed(() =>
  ['feed', 'page'].includes(route.name as string),
)

// Generate page name
const routePageName = computed(() => {
  switch (route.name) {
    case 'setting':
      return 'Profile'
    default:
      return route.name
  }
})
</script>

<template lang="pug">
.flex.justify-between.items-center.px-10.py-5.border-b
  .text-xl.font-medium.capitalize {{ routePageName }}
  
  SearchPageDialog(v-model:show="showSearch")
    .relative.flex.items-center.transition-all.px-3(:class='["border rounded-lg group", { "shadow-[inset_0_0_0_1px_#8A2BE2]" : focused }]' v-if='searchAble')
      label(for='search')
        Icon.w-5.h-5.shrink-0(name='ic:outline-search')
      Input#search.text-sm.ml-2.mr-3.p-0(
        ref='inputEl'
        v-model='input'
        name='search'
        type='text'
        placeholder='Search Pages'
        class='bg-transparent border-none focus-visible:ring-0 focus-visible:ring-offset-0'
      )
      .kb.text-xs(class='text-[#94A3B8]')  {{ isMacOs ? "⌘":"Ctrl" }}+K

  

</template>

<style lang="stylus" scoped></style>
